import React, {useCallback, useEffect, useState} from "react";

export function withLazyLoading(WrappedComponent: any,lazyWrapperClassName?,name = "",enabled=false) {
  return (props) => {
    const [lazy,setLazy] = useState(props.lazyLoading || enabled)
    const show = useCallback(() => {
      if (!lazy) return;
      setLazy(false)
    },[lazy])
    const [node,setNode] = useState(null)
    const ref = useCallback((node) => {
      if (!node) return;
      setNode(node)
    },[])
    useEffect(() => {
      if (!node) return;
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            show()
            observer.disconnect()
          }
        });
      });
      observer.observe(node);
      return () => {
        observer.disconnect();
      }
    },[node])
    if (lazyWrapperClassName === false || props.lazyWrapperClassName === false) {
      return <WrappedComponent childRef={ref} {...props} lazy={lazy} />
    } else {
      return <div className={"lazy-loading-wrapper " + (props.lazyWrapperClassName || lazyWrapperClassName || "")}
      ref={ref}><WrappedComponent {...props} lazy={lazy} /></div>
    }
  }
}

export const useLazyLoading = (enabled) => {
  const [lazy,setLazy] = useState(enabled)
  const show = useCallback(() => {
    if (!lazy) return;
    setLazy(false)
  },[lazy])
  const [node,setNode] = useState(null)
  const ref = useCallback((node) => {
    if (!node) return;
    setNode(node)
  },[])
  useEffect(() => {
    if (!node) return;
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          show()
          observer.disconnect()
        }
      });
    });
    observer.observe(node);
    return () => {
      observer.disconnect();
    }
  },[node])
  return {lazy,lazyRef:ref}
}

export const LazyLoadingWrapper = ({enabled,render} : {enabled,render: (lazy,lazyRef) => any}) => {
  const {lazy,lazyRef} = useLazyLoading(enabled)
  return render(lazy,lazyRef)
}