import { useRouter } from 'next/router';
import { useMinWidth } from '../../helpers/breakpoints';
import React from 'react';
import { useLazyLoading } from '../stock/lazy-loading';
import LoadingComponent from '../common/LoadingComponent';

const ads = {
  en: {
    mobile: [
      {
        name: '300x250_en%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/en-mobile-first.png',
      },
      {
        name: '300x250_ETF%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/en-mobile-second.png',
      },
    ],
    desktop: [
      {
        name: '970x250_en%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/en-desktop-first.png',
      },
      {
        name: '920x250_ETF%402x',
        width: 920,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/en-desktop-second.png',
      },
    ],
  },
  pt: {
    mobile: [
      {
        name: '300x250_pt%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/pt_mobile.png',
      },
    ],
    desktop: [
      {
        name: '970x250_pt%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/pt_desktop.png',
      },
    ],
  },
  nl: {
    mobile: [
      {
        name: '300x250_nl%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/nl_mobile.png',
      },
    ],
    desktop: [
      {
        name: '970x250_nl%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/nl_desktop.png',
      },
    ],
  },
  it: {
    mobile: [
      {
        name: '300x250_it%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/it_mobile.png',
      },
    ],
    desktop: [
      {
        name: '970x250_it%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/it_desktop.png',
      },
    ],
  },
  es: {
    mobile: [
      {
        name: '300x250_es%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/es_mobile.png',
      },
    ],
    desktop: [
      {
        name: '970x250_es%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/es_desktop.png',
      },
    ],
  },
  de: {
    mobile: [
      {
        name: '300x250_de%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/de_mobile.png',
      },
    ],
    desktop: [
      {
        name: '970x250_de%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/de_desktop.png',
      },
    ],
  },
  fr: {
    mobile: [
      {
        name: '300x250_fr%402x',
        width: 300,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/fr_mobile.png',
      },
    ],
    desktop: [
      {
        name: '970x250_fr%402x',
        width: 970,
        height: 250,
        url: 'https://iocharts-data.fra1.cdn.digitaloceanspaces.com/ads/fr_desktop.png',
      },
    ],
  },
};

const degiroDomainEnding = {
  en: '.com',
  da: '.dk',
  de: '.de',
  es: '.es',
  fr: '.fr',
  it: '.it',
  nl: '.nl',
  pl: '.pl',
  pt: '.pt',
  fi: '.fi',
  sv: '.se',
  cs: '.cz',
  el: '.gr',
};

export const noDegiro = ['hu', 'no', 'ro', 'af', 'ru', 'tr', 'sk'];

export function DegiroAdSlot({ version = 0 }) {
  const locale = useRouter().locale;
  const minWidth = useMinWidth(600);
  const adVariations = (ads[locale] || ads['en'])[minWidth ? 'desktop' : 'mobile'];
  const ad = adVariations[version % adVariations.length];
  const domainEnding = degiroDomainEnding[locale] ? degiroDomainEnding[locale] : degiroDomainEnding['en'];
  let { lazy, lazyRef } = useLazyLoading(true);
  return (
    <div ref={lazyRef} className={'degiro-container loading-cover-container'}>
      {lazy && (
        <div className={'loading-container loading-cover'}>
          <LoadingComponent />
        </div>
      )}
      <a
        href={
          'https://www.degiro' +
          domainEnding +
          '/?tap_a=113732-2ec4d4&tap_s=3590590-2adcd6&utm_source=kuharbalintev&utm_campaign=DEGIRO+EU&utm_medium=a&utm_content=' +
          ad.name
        }
        target="_BLANK"
        rel="nofollow noreferrer"
      >
        <img src={lazy ? '' : ad.url} width={ad.width} height={ad.height} alt={'AD'}></img>
      </a>
    </div>
  );
}
