import {useCallback, useEffect, useState} from "react";

export const useMediaQuery = (query) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
        if (e.matches) {
            setTargetReached(true);
        } else {
            setTargetReached(false);
        }
    }, []);

    useEffect(() => {
        const media = window.matchMedia(query);
        media.addListener(updateTarget);

        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
            setTargetReached(true);
        }

        return () => media.removeListener(updateTarget);
    }, []);

    return targetReached;
};

export const useMinWidth = (minWidth) => {
    return useMediaQuery(`(min-width: ${minWidth}px)`)
}

export const useMaxWidth = (maxWidth) => {
    return useMediaQuery(`(max-width: ${maxWidth}px)`)
}